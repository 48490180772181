/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Label, theme, Text, FlexContainer, Spacer } from "@nordcloud/gnui";
import { DATA_DESCRIPTION } from "../../constants";
import { useHandleThresholdDataFilterTabs } from "../../hooks";
import { ListSwitch } from "./ListSwitch";
import { TabSelector } from "./TabSelector";

export function ThresholdData() {
  const { tabs, selectedTab, onTabSelect } = useHandleThresholdDataFilterTabs();

  return (
    <FlexContainer
      css={{ width: "100%" }}
      direction="column"
      alignItems="flex-start"
    >
      <Label required name="Threshold Data" css={{ margin: 0 }} />
      <Text size="sm" color={theme.color.text.text03}>
        {DATA_DESCRIPTION}
      </Text>
      <TabSelector tabs={tabs} onTabSelect={onTabSelect} />
      <Spacer height={theme.spacing.spacing03} />
      <ListSwitch selectedTab={selectedTab} />
    </FlexContainer>
  );
}
