/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const REQUIRED_FIELD_MESSAGE = "This field is required.";

export const REQUIRED_EMAILS_MESSAGE = "At least one recipient is required.";

export const AMOUNT_DESCRIPTION =
  "Enter the 'Threshold Amount' and select either 'Monthly' or 'Daily' to set the frequency at which this limit applies.";

export const DATA_DESCRIPTION =
  "'Threshold Data' empowers you to take charge by setting monetary limits for specific aspects of your cloud usage.";

export const providersList = [
  {
    value: "AWS",
    id: "AWS",
  },
  {
    value: "AZURE",
    id: "AZURE",
  },
  {
    value: "GCP",
    id: "GCP",
  },
];
