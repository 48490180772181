/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { Button, FlexContainer, Sidebar, theme } from "@nordcloud/gnui";
import { UniversalWrap } from "~/components";
import { isNotNil } from "~/tools";
import { Threshold } from "../types";
import {
  ThresholdName,
  ThresholdData,
  AmountSelector,
  EmailNotificationSettings,
} from "./components";
import { useAddThreshold, useUpdateThreshold } from "./hooks";
import {
  ADD_THRESHOLDS_DEFAULT_VALUES,
  ThresholdFormData,
  ThresholdFormFields,
  thresholdSchema,
} from "./validators";

type Props = {
  threshold?: Threshold;
  sidebarState: {
    title: string;
    isOpen: boolean;
    onClick: () => void;
  };
  mode: "add" | "edit";
};

export function ThresholdSidebar({ threshold, sidebarState, mode }: Props) {
  const [onAddThreshold, { error: addError, loading: isAddLoading }] =
    useAddThreshold();

  const [onUpdateThreshold, { error: updateError, loading: isUpdateLoading }] =
    useUpdateThreshold(threshold?.id ?? "");

  const formMethods = useForm<ThresholdFormData>({
    resolver: yupResolver(thresholdSchema),
    defaultValues: isNotNil(threshold)
      ? mapUpdateDefaultValues(threshold)
      : ADD_THRESHOLDS_DEFAULT_VALUES,
  });

  const { handleSubmit, reset, trigger } = formMethods;

  const submitHandler = async (data: ThresholdFormData) => {
    const { onClick: onClose } = sidebarState;

    // check if Threshold Data is valid (./validators.ts)
    await trigger(ThresholdFormFields.PROVIDERS);

    const mutation = mode === "add" ? onAddThreshold : onUpdateThreshold;

    await mutation(data);

    onClose();
  };

  const isLoading = isAddLoading || isUpdateLoading;
  const error = addError || updateError;

  const formName = `${mode}-threshold`;

  const onCancel = () => {
    const { onClick: onClose } = sidebarState;

    reset(ADD_THRESHOLDS_DEFAULT_VALUES);

    onClose();
  };

  return (
    <UniversalWrap errorProps={{ error }} loaderProps={{ loading: isLoading }}>
      <FormProvider {...formMethods}>
        <form id={formName} onSubmit={handleSubmit(submitHandler)}>
          <Sidebar
            {...sidebarState}
            width="42rem"
            footer={<SidebarFooter formName={formName} onCancel={onCancel} />}
            onClick={onCancel}
          >
            <FlexContainer
              direction="column"
              alignItems="flex-start"
              gap={theme.spacing.spacing04}
            >
              <ThresholdName />
              <ThresholdData />
              <AmountSelector />
              <EmailNotificationSettings />
            </FlexContainer>
          </Sidebar>
        </form>
      </FormProvider>
    </UniversalWrap>
  );
}

function SidebarFooter({
  formName,
  onCancel,
}: {
  formName: string;
  onCancel: () => void;
}) {
  return (
    <FlexContainer
      padding={theme.spacing.spacing04}
      gap={theme.spacing.spacing04}
      alignItems="center"
    >
      <Button form={formName} type="submit" icon="plus">
        Apply
      </Button>
      <Button type="button" severity="low" onClick={onCancel}>
        Cancel
      </Button>
    </FlexContainer>
  );
}

function mapUpdateDefaultValues(threshold: Threshold): ThresholdFormData {
  return {
    ...threshold,
    thresholdAmount: String(threshold.thresholdAmount),
    externalRecipients: threshold.externalRecipients.filter(isNotNil),
  };
}
