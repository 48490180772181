/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { isEmpty } from "lodash";
import * as yup from "yup";
import { ThresholdGranularity } from "../types";
import { REQUIRED_EMAILS_MESSAGE, REQUIRED_FIELD_MESSAGE } from "./constants";

export const enum ThresholdFormFields {
  NAME = "name",
  THRESHOLD_AMOUNT = "thresholdAmount",
  GRANULARITY = "granularity",
  USER_RECIPIENTS = "userRecipients",
  EXTERNAL_RECIPIENTS = "externalRecipients",
  PROVIDERS = "providers",
  ACCOUNTS = "accounts",
  SERVICES = "services",
}

export type ThresholdFormData = {
  [ThresholdFormFields.NAME]: string;
  [ThresholdFormFields.THRESHOLD_AMOUNT]: string;
  [ThresholdFormFields.GRANULARITY]: ThresholdGranularity;
  [ThresholdFormFields.USER_RECIPIENTS]: string[];
  [ThresholdFormFields.EXTERNAL_RECIPIENTS]: string[];
  [ThresholdFormFields.PROVIDERS]: string[];
  [ThresholdFormFields.ACCOUNTS]: string[];
  [ThresholdFormFields.SERVICES]: string[];
};

const emailRegex = /^[\w%+.-]+@[\d.a-z-]+\.[a-z]{2,}$/i;

export const thresholdSchema = yup.object().shape({
  [ThresholdFormFields.NAME]: yup.string().required(REQUIRED_FIELD_MESSAGE),
  [ThresholdFormFields.THRESHOLD_AMOUNT]: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .test("is-number", "Invalid number", (value) => {
      if (!value) {
        return true;
      }
      return !isNaN(Number(value));
    }),
  [ThresholdFormFields.GRANULARITY]: yup.string().required(),
  [ThresholdFormFields.USER_RECIPIENTS]: yup
    .array()
    .of(yup.string())
    .required(REQUIRED_FIELD_MESSAGE)
    .test(
      "is-not-empty",
      REQUIRED_EMAILS_MESSAGE,
      (value) => (value ?? []).length > 0
    ),
  [ThresholdFormFields.EXTERNAL_RECIPIENTS]: yup
    .array()
    .of(yup.string().matches(emailRegex, "Provided emails must be valid"))
    .required(REQUIRED_FIELD_MESSAGE),
  // validation (one of 3 arrays (providers, accounts, services must contain one item
  // while other two should be empty) for providers, accounts and services is handled here
  [ThresholdFormFields.PROVIDERS]: yup
    .array()
    .of(yup.string())
    .when([ThresholdFormFields.ACCOUNTS, ThresholdFormFields.SERVICES], {
      is: (accounts: string[], services: string[]) =>
        isEmpty(accounts) && isEmpty(services),
      then: (schema) =>
        schema.min(1, REQUIRED_FIELD_MESSAGE).max(1, REQUIRED_FIELD_MESSAGE),
    }),
});

export const ADD_THRESHOLDS_DEFAULT_VALUES = {
  [ThresholdFormFields.NAME]: "",
  [ThresholdFormFields.THRESHOLD_AMOUNT]: "",
  [ThresholdFormFields.GRANULARITY]: ThresholdGranularity.Daily,
  [ThresholdFormFields.USER_RECIPIENTS]: [],
  [ThresholdFormFields.EXTERNAL_RECIPIENTS]: [],
  [ThresholdFormFields.PROVIDERS]: [],
  [ThresholdFormFields.ACCOUNTS]: [],
  [ThresholdFormFields.SERVICES]: [],
};
