/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useRef } from "react";
import { Else, If, Then, When } from "react-if";
import { Radio, theme, InputSearch, Text } from "@nordcloud/gnui";
import { isNotEmpty, isNotNil } from "~/tools";
import { useHandleListSearch } from "../hooks";
import { ThresholdDataSelectItem } from "../types";

type Props = {
  optionsList: ThresholdDataSelectItem[];
  optionSelected?: ThresholdDataSelectItem;
  searchable?: boolean;
  onSelect: (valueClicked?: ThresholdDataSelectItem) => void;
};

export function RadioOptionList({
  optionsList,
  optionSelected,
  searchable,
  onSelect,
}: Props) {
  const [searchedList, searchValue, onSearchUpdate] =
    useHandleListSearch(optionsList);

  const searchRef = useRef<HTMLInputElement>(null);

  // InputSearch GNUI component search button has button type set to "submit"
  // and it submits the form on click
  // This disables the behavior
  useEffect(() => {
    const searchButton = searchRef.current?.parentElement?.childNodes[1]
      .childNodes[0] as HTMLButtonElement;

    if (isNotNil(searchButton)) {
      searchButton.type = "button";
    }
  }, [searchRef]);

  return (
    <div
      css={{
        width: "100%",
        maxHeight: "22rem",
        overflow: "scroll",
        position: "relative",
      }}
    >
      <When condition={searchable}>
        <div css={{ paddingBottom: theme.spacing.spacing04 }}>
          <InputSearch
            ref={searchRef}
            icon="search"
            value={searchValue}
            onChange={(event) => onSearchUpdate(event.currentTarget.value)}
          />
        </div>
      </When>
      <If condition={isNotEmpty(searchedList)}>
        <Then>
          {searchedList.map(({ id, value }) => (
            <Radio
              key={id}
              value={id}
              id={id}
              labelText={value}
              checked={optionSelected?.id === id}
              onChange={(event) =>
                onSelect(
                  optionsList.find(
                    (option) => event.currentTarget.value === option.id
                  )
                )
              }
            />
          ))}
        </Then>
        <Else>
          <Text>No data available.</Text>
        </Else>
      </If>
    </div>
  );
}
