/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { When } from "react-if";
import {
  Accordion,
  AccordionHeader,
  Checkbox,
  InputSearch,
} from "@nordcloud/gnui";
import { useGetUsersPermissionsLazyQuery } from "~/generated/graphql";
import { FormGroup } from "~/components";
import { ERROR_TEXT } from "~/constants";
import { showError } from "~/services/toast";
import { isNotNil } from "~/tools";
import { SortByOrder, SortByValue } from "~/views/settings/types";
import { ThresholdFormData, ThresholdFormFields } from "../validators";
import { StyledBox, ListContainer, StyledClearButton } from "./styled";

export function InternalRecipients() {
  const [fetchUsers] = useGetUsersPermissionsLazyQuery();

  const [users, setUsers] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedUsers, setSelectedUsers] = useState(0);

  const {
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext<ThresholdFormData>();

  const onSearchUpdate = useCallback(
    async (search: string) => {
      try {
        const data = await fetchUsers({
          variables: {
            limit: 10,
            page: 0,
            sortByValue: SortByValue.UserEmailId,
            sortByOrder: SortByOrder.Descending,
            searchByValue: search,
          },
        });

        setUsers(
          (data.data?.usersPermissions?.usersPermissions ?? [])
            .filter(isNotNil)
            .map(({ email }) => email)
        );
      } catch {
        showError(ERROR_TEXT.default);
      }
    },
    [fetchUsers]
  );

  const handleSelect = async (event: FormEvent<HTMLInputElement>) => {
    const currentRecipients = getValues(ThresholdFormFields.USER_RECIPIENTS);
    const emailClicked = event.currentTarget.value;

    if (currentRecipients.includes(emailClicked)) {
      const selectedRecipients = currentRecipients.filter(
        (value) => value !== emailClicked
      );
      setSelectedUsers(selectedRecipients.length);

      return setValue(ThresholdFormFields.USER_RECIPIENTS, selectedRecipients);
    }

    const selectedRecipients = [...currentRecipients, emailClicked];
    setSelectedUsers(selectedRecipients.length);

    setValue(ThresholdFormFields.USER_RECIPIENTS, selectedRecipients);

    await trigger(ThresholdFormFields.USER_RECIPIENTS);
  };

  const handleClear = () => {
    setSelectedUsers(0);
    setValue(ThresholdFormFields.USER_RECIPIENTS, []);
  };

  useEffect(() => {
    onSearchUpdate(searchValue);
    setSelectedUsers(getValues(ThresholdFormFields.USER_RECIPIENTS).length);
  }, [searchValue, onSearchUpdate]);

  const searchRef = useRef<HTMLInputElement>(null);

  // InputSearch GNUI component search button has button type set to "submit"
  // and it submits the form on click
  // This disables the behavior
  useEffect(() => {
    const searchButton = searchRef.current?.parentElement?.childNodes[1]
      .childNodes[0] as HTMLButtonElement;

    if (isNotNil(searchButton)) {
      searchButton.type = "button";
    }
  }, [searchRef]);

  return (
    <FormGroup error={errors[ThresholdFormFields.USER_RECIPIENTS]}>
      <div css={{ width: "100%", position: "relative" }}>
        <Accordion>
          <AccordionHeader title="MCA Users">
            <StyledBox boxStyle="lightGrey">
              <InputSearch
                ref={searchRef}
                icon="search"
                value={searchValue}
                onChange={(event) => setSearchValue(event.currentTarget.value)}
              />
              <Controller
                name={ThresholdFormFields.USER_RECIPIENTS}
                render={() => (
                  <ListContainer>
                    {users.map((email) => (
                      <Checkbox
                        key={email}
                        value={email}
                        id={email}
                        labelText={email}
                        checked={getValues(
                          ThresholdFormFields.USER_RECIPIENTS
                        ).includes(email)}
                        onChange={handleSelect}
                      />
                    ))}
                  </ListContainer>
                )}
              />
            </StyledBox>
          </AccordionHeader>
          <When condition={selectedUsers > 0}>
            <StyledClearButton onClick={handleClear}>
              Clear: {selectedUsers}
            </StyledClearButton>
          </When>
        </Accordion>
      </div>
    </FormGroup>
  );
}
